<template>
	<v-card flat>
		<div>
			<!-- <s-toolbar
				dark
				:color="'#BAB6B5'"
				close
				@close="$emit('close')"
			></s-toolbar> -->

			<v-col align="center" cols="12" v-for="item in selectedItemsSend">
				<qr-code
					:text="
						item.LlpID +
						', Jabas: ' +
						item.LlpQuantityJaba +
						',' +
						item.TypeCaliberName +
						',' +
						item.TypeCultiveName +
						',' +
						item.VrtName +
						',' +
						item.TypeDestinyName +
						',' +
						item.TypeDestiny
					"
					error-level="H"
				>
				</qr-code
				><br />
			</v-col>
		</div>
	</v-card>
</template>

<script>
	import qrCode from "vue-qr-generator";
	export default {
		props: {
			selectedItemsSend: null,
		},
		components: { qrCode },
	};
</script>

<style></style>
