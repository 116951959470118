import Service from "../Service";
 
const resource = "/PrfCreatePalletTemp/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },
}