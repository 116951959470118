<template>
	<v-card>
		<div>
			<v-col cols="12">
				<s-select-definition 
                    label="Destino" 
                    :def="1181" 
                    style="border-right: groove;" 
                    v-model="items.TypeDestinyNew"
                />
			</v-col>

            <v-col cols="12" lg="12" md="12">
				<s-textarea
                    label="Motivo"
                    v-model="items.LptObservations"
                >

                </s-textarea>
			</v-col>

			
		</div>
        <v-card-actions>
            <v-col cols="4" lg="2" md="2">
				<v-btn 
                    small 
                    elevation="3" 
                    color="success" 
                    style="width:100%"
                    @click="transfer()"
                >
					Enviar
				</v-btn>
			</v-col>
            
            <v-col cols="4" lg="2" md="2">
				<v-btn 
                    small 
                    elevation="3" 
                    color="error" 
                    style="width:100%"
                    @click="close()"
                >
					Close
				</v-btn>
			</v-col>
        </v-card-actions>
	</v-card>
    
</template>

<script>
    import _sCalibratedService from '../../../services/FreshProduction/PrfLotProductionCalibratedService'
	export default {
        props:{
            value:{},
        },
        data: () => ({
            items:{LptObservations: null, TypeDestinyNew: null},
        }),
        created() {
            console.log("value", this.value);
        },

        methods: {
            close(){
                this.$emit("close")
            },

            transfer(){
                
                if(this.items.TypeDestinyNew == null){
                    this.$fun.alert("Seleccione un destino", "warning")
                    return;
                }

                if(this.items.LptObservations == null){
                    this.$fun.alert("Ingrese una observacion", "warning")
                    return;
                }

                this.items.LlpID = this.value.LlpID;
                this.items.TypeDestinyOld = this.value.TypeDestiny;
                this.items.SecStatus = 1;
                this.items.UsrCreateID = this.$fun.getUserID();

                this.$fun.alert("Esta seguro de tranferir ? ", "question").then((val) => {
                    if (val.value) {
                        _sCalibratedService.transfer(this.items, this.$fun.getUserID()).then((r) => {
                            this.$fun.alert("Registro transferido correctamente", "success")
                            this.close();
                        });
                    }
                });

                console.log(this.items);
            },
        },
    };
</script>

<style></style>
