var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('div',_vm._l((_vm.selectedItemsSend),function(item){return _c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('qr-code',{attrs:{"text":item.LlpID +
					', Jabas: ' +
					item.LlpQuantityJaba +
					',' +
					item.TypeCaliberName +
					',' +
					item.TypeCultiveName +
					',' +
					item.VrtName +
					',' +
					item.TypeDestinyName +
					',' +
					item.TypeDestiny,"error-level":"H"}}),_c('br')],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }